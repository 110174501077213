import { lazy, Suspense } from 'react';

const MonitorComponent = lazy(
  () => import(/* webpackChunkName: "monitor" */ 'monitor'),
);

const AsyncMonitorApp = () => (
  <Suspense fallback={<span />}>
    <MonitorComponent />
  </Suspense>
);

export default AsyncMonitorApp;
